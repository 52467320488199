<template>
    <div class="main-content" style="
      padding-bottom: 10vh !important;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width: 100%;
        background-position: center;
      ">
        <div class="dashboard-banner position-relative">
            <div class="d-flex justify-content-center align-items-end">
            <!-- <h4 class="mt-5 mx-4 text-center text-primary">{{ $t("dashboard") }}</h4> -->
            </div>
            <div class="position-absolute h-100 w-100" style="background: #d2d2d24d" v-if="isLoading">
            <div class="px-2 py-1 rounded" style="
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: #add8e6;
                ">
                <p class="mb-0">{{ $t("loading...") }}</p>
            </div>
            </div>
        </div>
        <div class="m-3 mx-3 mt-4">
            <div class="header">
                <!-- <img src="../../../assets/images/etnex_buy/back_icon.png" alt="Back" class="back-arrow-icon" @click="goBack"> -->
                <h1 class="main_text text text_main_title">{{ $t("my_wallet") }}</h1>
                <!-- <img src="../../../assets/images/etnex_buy/question.png" alt="Info" class="info-icon"> -->
            </div>

            <div class="etnex-purchase-container colorful_bg">
                <div class="purchase-content row">
                  <div class="col-6">
                    <div class="asset-info">
                    <p class="text-12 text mb-0">ETNEX - </p>
                    <p class="text-15 text font-weight-semibold mb-0">{{ $t("my_wallet") }}</p>
                    <p class="text-8 text mb-0">{{ $t("Your Gateway to Smart Profitability") }}</p>
                   
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="asset-info text-right">
                      <img src="../../../assets/images/etnex_home/Blockchain.png" alt="Info" class="info-icon">
                    </div>
                  </div>
                </div>
            </div>
            
            <div class="black-blue-card mb-3">
              <div class="purchase-content row">
                  <div class="col-6">
                    <div class="asset-info text-left">
                      <img src="../../../assets/images/logo_token.png" alt="ETNEX Icon" class="icon" style="max-width: 12%;"/>
                      <span class="text-10 text">{{ $t("ETNEX") }}</span>
                      <div class="d-flex align-items-center justify-content-start mb-1">
                      <p class="text text-20 text-white weight_500 mb-0 ml-0">${{ this.market_price }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="purchase-statement-container mb-4">
              <button class="make-purchase-button colorful_bg mt-2" @click="$router.push('/web/upgradePackage')">
                  {{ $t("buy_etnex") }}
              </button>
            </div>

            <div class="black-blue-card mb-3">
              <div class="purchase-content row">
                  <div class="col-6">
                    <div class="asset-info text-left">
                    <h3 class="text-10 text">{{ $t("total_withdraw") }}</h3>
                    
                    <div class="d-flex align-items-center justify-content-start mb-1">
                      <p class="asset-value text-16 text weight_600 mr-2">${{ parseFloat(this.total_withdraw).toLocaleString() }}</p>
                      <img src="../../../assets/images/etnex_wallet/usdt_icon.png" alt="ETNEX Icon" style="width: 12%;"/>
                    </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="asset-info text-right">
                    <h3 class="text-10 text">{{ $t("a_withdraw_limit") }}</h3>
                    
                    <div class="d-flex align-items-center justify-content-end mb-1">
                      <p class="asset-value text-16 text weight_600 mr-2">${{ parseFloat(this.a_withdraw_limit).toLocaleString() }}</p>
                      <img src="../../../assets/images/etnex_wallet/usdt_icon.png" alt="ETNEX Icon" style="width: 12%;"/>
                    </div>
                    </div>
                  </div>
                </div>
            </div>

            <h2 class="text_h2_etnex text-light-green pt-10">{{ $t("assets") }}</h2>
              <b-row no-gutters>
                  <b-col cols="12" @click="$router.push('/web/wallet/record?type=point1')">
                    <b-card class="dark_green_bg" no-body>
                        <div class="mt-2 px-3">
                            <h3 class="text text_16 weight_700 my-2">{{ $t("usdt") }}</h3>
                        </div>
                       <b-col cols="12" class="d-flex align-items-center">
                          <img src="../../../assets/images/etnex_wallet/usdt_icon.png" alt="USDT Icon" class="icon" style="height: 25px"/>
                          <p class="text text-20 text-white weight_500 mb-0 ml-2">{{ this.usdt }} USDT</p>
                        </b-col>

                        <b-col cols="12" class="d-flex justify-content-between">
                            <div class="mt-3 mb-0">
                                <!-- <p class="text text_10_color">$2,570.50</p> -->
                            </div>
                        </b-col>
                    </b-card>
                  </b-col>
              </b-row>

              <b-row no-gutters>
                  <b-col cols="6 py-2" @click="$router.push('/web/wallet/record?type=point2')">
                      <b-card class="dark_purple_bg py-2 mr-2" no-body>
                          <b-col cols="12" class="d-flex justify-content-between">
                              <div class="mt-2" style="padding-bottom: 12px;">
                                  <h3 class="text text_16 weight_700">{{ $t("etnex_freeze") }}</h3>
                              </div>
                          </b-col>
                          <b-col cols="12" class="d-flex align-items-center">
                              <img src="../../../assets/images/logo_token.png" alt="ETNEX Icon" class="icon" style="max-width: 14%;"/>
                              <p class="text mb-0 ml-0">{{ this.etnex_freeze }}</p>
                          </b-col>
                          <b-col cols="12" class="d-flex justify-content-between">
                              <div class="mt-3 mb-0">
                                  <!-- <p class="text text_10_color">$2,570.50</p> -->
                              </div>
                          </b-col>
                      </b-card>
                  </b-col>
                   <b-col cols="6 py-2" @click="$router.push('/web/wallet/record?type=point3')">
                      <b-card class="dark_purple_bg py-2 ml-2" no-body>
                          <b-col cols="12" class="d-flex justify-content-between">
                              <div class="mt-2" style="padding-bottom: 12px;">
                                  <h3 class="text text_16 weight_700">{{ $t("etnex_release") }}</h3>
                              </div>
                          </b-col>
                          <b-col cols="12" class="d-flex align-items-center">
                              <img src="../../../assets/images/logo_token.png" alt="ETNEX Icon" class="icon" style="max-width: 14%;"/>
                              <p class="text mb-0 ml-0">{{ this.etnex }}</p>
                          </b-col>
                          <b-col cols="12" class="d-flex justify-content-between">
                              <div class="mt-3 mb-0">
                                  <!-- <p class="text text_10_color">$2,570.50</p> -->
                              </div>
                          </b-col>
                      </b-card>
                  </b-col>
              </b-row>
              <b-row no-gutters>
                <b-col cols="12 py-0" @click="$router.push('/web/wallet/record?type=point4')">
                    <b-card class="dark_purple_bg py-2" no-body>
                        <b-col cols="12" class="d-flex justify-content-between">
                            <div class="mt-2" style="padding-bottom: 12px;">
                                <h3 class="text text_16 weight_700">{{ $t("point4") }}</h3>
                            </div>
                        </b-col>
                        <b-col cols="12" class="d-flex align-items-center justify-content-between">
                            <div>
                              <img src="../../../assets/images/logo_token.png" alt="ETNEX Icon" class="icon" style="max-width: 23px;"/>
                              <span class="text mb-0 ml-0">{{ this.point4 }}</span>
                            </div>  
                            <b-button class="make-purchase-button colorful_bg" style="width: 42%;" @click="$router.push('/web/redeemAble')"> {{ $t('redeem') }}</b-button>
                        </b-col>
                        <!-- <b-col cols="12" class="d-flex justify-content-between">
                            <div class="mt-3 mb-0">
                           
                            </div>
                        </b-col> -->
                    </b-card>
                </b-col>
              </b-row>  
            <div class="purchase-statement-container">
                <button class="make-purchase-button colorful_bg mt-3" @click="$router.push('/web/deposit/deposit')">
                    {{ $t("deposit") }}
                </button>
                <button class="make-purchase-button colorful_bg mt-3" @click="$router.push('/web/withdraw/withdraw')">
                    {{ $t("withdraw") }}
                </button>
                <button class="make-purchase-button colorful_bg mt-3" @click="$router.push('/web/sell')">
                    {{ $t("sell_etnex") }}
                </button>
                <!-- <button class="make-purchase-button colorful_bg mt-3" @click="$router.push('/web/redeem')">
                    {{ $t("redeem_etnex") }}
                </button> -->
                <!-- <button class="make-purchase-button colorful_bg mt-3" @click="$router.push('/web/convert')">
                    {{ $t("convert_etnex") }}
                </button> -->
            </div>
            <h2 class="text_h2_etnex text text-light-green mt-3 pt-10">{{ $t("recent_transactions") }}</h2>
            <div class="scrollable-container">
              <div v-if="walletRecord.length > 0">
                <div class="notification-card" v-for="record in walletRecord" :key="record.id">
                    <div class="icon">
                        <img src="../../../assets/images/etnex_wallet/leftright.png" alt="icon" class="icon" width="50px">
                    </div>
                    <div class="content">
                    <div class="upper-row">
                        <span class="title text">{{ $i18n.locale === 'en' ? record.detailen : record.detail }}</span>
                        <span class="date text">{{ formatDate(record.created_at) }}</span>
                    </div>
                    <div class="lower-row">
                        <span class="con_address text"></span>
                        <span class="change" :style="{ color: record.action === '-' ? 'red' : '#A7FDED' }">{{ record.action }}${{ record.found }} <span class="change" style="font-size: 14px; padding: 0px">{{ formatRecord(record) }}</span></span>
                        
                    </div>
                  </div>
                </div>
              </div>
              <h3 v-else class="no-data-text text">
                {{ $t("no_data") }}
              </h3>
            </div>
            <div class="purchase-statement-container-more" v-if="moreRecords">
                <button class="make-purchase-button-more" style="width: 40% !important;" @click="loadMore">
                    {{ $t("load_more") }}
                </button>
            </div>
        </div>
    </div>
  </template>
  <script>
  import { getMemberInfo, getUserWalletRecord,getDownloadLink } from "../../../system/api/api";
  import { handleError } from "../../../system/handleRes";
  import { mapGetters } from "vuex";
  export default {
    components: {
      ...mapGetters(["lang"]),
    },
    data() {
      return {
        usdt: '',
        etnex: '',
        etnex_freeze: '',
        point4: '',
        total_asset: 0,
        percentage: 0,
        currentPage: 1,
        market_price: '',
        type: "0",
        walletRecord: [],
        moreRecords: false,
        a_withdraw_limit: 0,
        total_withdraw: 0,
      };
    },
    mounted() {
      this.loadItems();
      this.loadItems_etnexprice(),
      this.loadItems_wallet();
    },
    methods: {
      loadItems_etnexprice(){
        var result_lookup = getDownloadLink();
        var self = this;
        result_lookup
          .then(function(value){
            self.market_price = value.data.data.system.etnex_price; 
          })
          .catch(function(error) {
            self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
        });
      },
      goBack() {
        this.$router.go(-1)
      },
      formatDate(datetime) {
        return datetime.split(' ')[0];
      },
      loadMore() {
        this.currentPage++;
        this.loadItems_wallet();
      },

      loadItems(){
        var result = getMemberInfo();
        var self = this;
        this.isLoading = true;
        result
          .then(function (value) {
            self.isLoading = false;
            self.usdt = value.data.data.point1;
            self.etnex_freeze = value.data.data.point2;
            self.etnex = value.data.data.point3;
            self.point4 = value.data.data.point4;
            self.total_asset = value.data.data.total_asset;  
            var total_cap  = value.data.data.total_cap;
            var total_withdraw = value.data.data.total_withdraw;
            // var total_cap  = 0;
            // var total_withdraw = 0;
            var c_percentage = (parseFloat(total_withdraw) / parseFloat(total_cap)) * 100;
            

            if (parseFloat(total_cap) === 0 && parseFloat(total_withdraw) === 0) {
              c_percentage = 0;
            } else {
              c_percentage = (parseFloat(total_withdraw) / parseFloat(total_cap)) * 100;
            }

            self.percentage = c_percentage.toFixed();
            self.a_withdraw_limit = parseFloat(total_cap) - parseFloat(total_withdraw);
            self.total_withdraw = value.data.data.total_withdraw;
          })
          .catch(function (error) {
            self.isLoading = false;
            self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
          });
      },
      loadItems_wallet(){
        var searchParam = "";
          var result = getUserWalletRecord(
          this.type,
          this.currentPage,
          searchParam
        );
        var self = this;
        result
          .then(function (value) {
            // console.log("API Response:", value);
            self.walletRecord = self.walletRecord.concat(value.data.data.record.data);
            if (!value.data.data.record.next_page_url) {
              self.moreRecords = false;
            }else{
              self.moreRecords = true;
            }
            console.log(self.walletRecord);

          })
          .catch(function (error) {
            self.isLoading = false;
            self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
          });
      },
      formatRecord(record) {
        const typeMap = {
          '1': 'USDT',
          '2': 'ETNEX Freeze',
          '3': 'ETNEX'
        };
        // const symbol = record.in_type !== '0' ? '+' : '-';
        const type = typeMap[record.in_type] || typeMap[record.out_type];
        return `${type}`;
        
      }
    },
    computed: {
    circumference() {
      return 2 * Math.PI * 14;
    }
  }
  };
  </script>
  
<style scoped>

.text-16{
  font-size: 16px !important;
}

.scrollable-container {
    max-height: 240px; 
    overflow-y: auto; 
    }
    .notification-card {
        display: flex;
        align-items: center;
        background: linear-gradient(125deg, #01050C,#030F20, #2264CC);
        color: #fff;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 7px;
    }

    .icon {
        margin-right: 5px;
        flex-shrink: 0;
    }

    .icon img {
        background-color: transparent;
        border-radius: 5px;
        padding: 8px;
    }

    .content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .upper-row, .lower-row {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    .title {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1px;
    }

    .date {
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 1px;
    }

    .stat-circle-container {
        flex: 0 0 auto;
    }
    .stat-circle
    {
        circle.progress
        {
            border: 1px solid yellow;
            fill: none;
            stroke-width: 2;
            /* stroke-dasharray: 51 51; */
            /* stroke-dashoffset: -51; */
        }
        
    }
    circle.bg
    {
        fill: #C20651;
        stroke: #251740;
        stroke-width: 3;
    }

    text
    {
        font-size: 4px;
        text-anchor: middle;
        fill: #fff;
    }
    .text_main_title{
      flex-grow: 1;
      text-align: center;
    }


    .no-data-text{
      font-weight: 400;
      font-size: 16px;
      flex-grow: 1;
      text-align: center;
      margin-top: 40px;
      color: var(--Secondary, #7E8088) !important;
    }

  .pt-10{
    padding-top: 10px;
  }

  .mb-0{
    margin-bottom: 0rem;
  }
  
  .weight_700{
    font-weight: 700;
  }

  .weight_600{
    font-weight: 600;
  }
  .text{
    color: var(--Main, #FFF);
    font-family: Poppins;
    font-style: normal;
  }
  
  
  .main_text{
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
    margin-top: 7px;
  }

  .text_14{
    margin-bottom: -1px;
    font-size: 14px;
    line-height: 18px;
  }

  .text_16{
    margin-bottom: 4px;
    font-size: 16px;
    line-height: 18px;
  }

  .text_10_color{
    color: var(--Primary, #3BEDEA);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .asset-value{
    margin-bottom: -1px;
    font-size: 18px;
    line-height: 18px;
  }

  .bg-left{
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.12);
    backdrop-filter: blur(28.070175170898438px);
  }

  .header {
    display: flex;
    /* padding: 0px 16px; */
    margin-top: 4rem !important;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  
  .back-arrow-icon, .info-icon {
    cursor: pointer;
  }
  
  .purchase-content h2 {
    margin-bottom: 16px;
  }
  
  .purchase-details {
    border-radius: 8px;
  }
  
  .make-purchase-button-more {
    width: 90%;
    border-radius: 8px;
    background: var(--Primary, #3BEDEA);
    color: rgb(0, 0, 0);
    padding: 12px;
    border: none;
    color: var(--Dark-Purple, #251740);
    text-align: center;
    /* Button */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
  }
  
  
  .purchase-statement-container-more {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    color: white;
    font-family: 'Poppins', sans-serif;
  }

  </style>
  